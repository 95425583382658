import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID || "dummy_user_pool_id",
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID || "dummy_client_id",
};

export const userPool = new CognitoUserPool(poolData);

export const userAttributeList = [];

/**
 * サインアウトします.
 */
export function signout(): void {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
}

/**
 * ユーザ認証のチェックをおこないます.
 * @returns CognitoUser
 */
export function checkAuthentication(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (user == null) {
      reject();
    } else {
      user.getSession(function (
        error: Error | null,
        session: CognitoUserSession | null
      ) {
        if (error) {
          reject();
        } else {
          if (!session?.isValid) {
            reject("invalid session.");
          } else {
            resolve();
          }
        }
      });
    }
  });
}

/**
 * ユーザの認証をおこないます.
 * @param username ユーザ名（メールアドレス）
 * @param password パスワード
 * @returns CognitoUser
 */
export function authenticateUser(
  username: string,
  password: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
    user.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
      }),
      {
        onFailure: function (err: Error) {
          console.log(err);
          console.log("onFailure");
          reject(err);
        },
        onSuccess: function () {
          console.log("onSuccess");
          resolve();
        },
        newPasswordRequired: function () {
          console.log("newPasswordRequired");
          user.completeNewPasswordChallenge(password, {}, this);
        },
      }
    );
  });
}
