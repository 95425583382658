import Vue from "vue";
import Vuex from "vuex";
import { INotification } from "@/store/modules/notification";

Vue.use(Vuex);

export interface IRootState {
  notification: INotification;
}

// Declare empty store first, dynamically register all modules later.
const store = new Vuex.Store<IRootState>({});

export default store;
