










































import Vue from "vue";
import { CognitoState } from "@/store/modules/cognito";
import shopSettingService from "@/service/shopSettingService";

export default Vue.extend({
  name: "App",
  data: () => ({
    activeTab: "/",
    tabs: [
      { key: "order", name: "注文", route: "/order" },
      { key: "member", name: "顧客", route: "/member" },
      { key: "item", name: "商品", route: "/item" },
      { key: "setting", name: "設定", route: "/setting/information" },
    ],
  }),
  mounted() {
    window.onload = async () => {
      const setting = await shopSettingService.getShopSetting();
      //ショップ設定が存在しない場合は作成
      await shopSettingService.createShopSetting();
    };
  },
  computed: {
    authenticated: function () {
      return CognitoState.authenticated;
    },
  },
});
