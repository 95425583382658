import Vue from "vue";
import VueRouter from "vue-router";
import { CognitoState } from "@/store/modules/cognito";

Vue.use(VueRouter);

const signout = async (to: any, from: any, next: any) => {
  CognitoState.signout();
  next("/signin");
};

const routes = [
  {
    path: "/",
    name: "Top",
    redirect: { name: "Order" },
    meta: { requiredAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import("@/components/pages/Signin.vue"),
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("@/components/pages/order/Index.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/order/:orderGuid",
    name: "Order",
    component: () => import("@/components/pages/order/Index.vue"),
    meta: { requiredAuth: true },
    props: true,
  },
  {
    path: "/item",
    name: "Item",
    component: () => import("@/components/pages/item/Index.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/member",
    name: "Member",
    component: () => import("@/components/pages/member/Index.vue"),
    meta: { requiredAuth: true },
  },
  {
    path: "/setting",
    component: () => import("@/components/pages/setting/Index.vue"),
    redirect: { name: "InformationSetting" },
    children: [
      {
        //ショップのお知らせ
        path: "information",
        name: "InformationSetting",
        component: () => import("@/components/pages/setting/Information.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ショップ用途
        path: "purpose",
        name: "PurposeSetting",
        component: () => import("@/components/pages/setting/Purpose.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ショップ商品カテゴリ
        path: "item_category",
        name: "ItemCategorySetting",
        component: () => import("@/components/pages/setting/ItemCategory.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ショップ商品ブランド
        path: "item_brand",
        name: "ItemBrandSetting",
        component: () => import("@/components/pages/setting/ItemBrand.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "catalog",
        name: "CatalogSetting",
        component: () => import("@/components/pages/setting/Catalog.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "property",
        name: "PropertySetting",
        component: () => import("@/components/pages/setting/Property.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "option_item",
        name: "OptionItemSetting",
        component: () => import("@/components/pages/setting/OptionItem.vue"),
        meta: { requiredAuth: true },
      },
      {
        path: "option",
        name: "OptionSetting",
        component: () => import("@/components/pages/setting/Option.vue"),
        meta: { requiredAuth: true },
      },
      {
        //割引有効日数
        path: "discount_rate_deferment_days",
        name: "DiscountRateDefermentDaysSetting",
        component: () =>
          import("@/components/pages/setting/DiscountRateDefermentDays.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ジャンプアップ割引率
        path: "discount_rate",
        name: "DiscountRateSetting",
        component: () => import("@/components/pages/setting/DiscountRate.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ポイント付与率
        path: "point_rate",
        name: "PointRateSetting",
        component: () => import("@/components/pages/setting/PointRate.vue"),
        meta: { requiredAuth: true },
      },
      {
        //クーポン
        path: "coupon",
        name: "CouponSetting",
        component: () => import("@/components/pages/setting/Coupon.vue"),
        meta: { requiredAuth: true },
      },
      {
        //配送地域別送料
        path: "domestic_postage_fee",
        name: "DomesticPostageFeeSetting",
        component: () =>
          import("@/components/pages/setting/DomesticPostageFee.vue"),
        meta: { requiredAuth: true },
      },
      {
        //NP後払い上限金額
        path: "deferred_payment",
        name: "DeferredPaymentSetting",
        component: () =>
          import("@/components/pages/setting/DeferredPayment.vue"),
        meta: { requiredAuth: true },
      },
      {
        //ショップ基本情報
        path: "shop_setting",
        name: "ShopSetting",
        component: () => import("@/components/pages/setting/ShopSetting.vue"),
        meta: { requiredAuth: true },
      },
      {
        //商品価格帯
        path: "item_price_range",
        name: "ItemPriceRangeSetting",
        component: () =>
          import("@/components/pages/setting/ItemPriceRange.vue"),
        meta: { requiredAuth: true },
      },
      {
        //メールテンプレート
        path: "email_template",
        name: "EmailTemplateSetting",
        component: () =>
          import("@/components/pages/setting/EmailTemplate.vue"),
        meta: { requiredAuth: true },
      },
    ],
  },
  {
    path: "/signout",
    beforeEnter: signout,
  },
  {
    path: "*",
    component: () => import("@/components/pages/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    CognitoState.checkAuthentication().then(() => {
      if (CognitoState.authenticated) {
        //ログイン済み
        next();
      } else {
        //未ログイン
        next({
          path: "/signin",
          query: { redirect: to.fullPath },
        });
      }
    });
  } else {
    next();
  }
});

export default router;
