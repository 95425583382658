import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import * as Cognito from "@/plugins/cognito";
import store from "@/store";

export interface ICognitoState {
  authenticating: boolean;
  authenticated: boolean;
}

@Module({ dynamic: true, store, name: "cognito", namespaced: true })
class CognitoStateModule extends VuexModule implements ICognitoState {
  authenticating = false;
  authenticated = false;

  @Mutation
  setAuthenticating(authenticating: boolean) {
    this.authenticating = authenticating;
  }

  @Mutation
  setAuthenticated(isAuthenticated: boolean) {
    this.authenticated = isAuthenticated;
  }

  @Action({ rawError: true })
  signout() {
    Cognito.signout();
    this.setAuthenticating(false);
    this.setAuthenticated(false);
  }

  @Action({ rawError: true })
  async checkAuthentication() {
    this.setAuthenticating(true);
    await Cognito.checkAuthentication()
      .then(() => {
        this.setAuthenticating(false);
        this.setAuthenticated(true);
      })
      .catch(() => {
        this.setAuthenticating(false);
        this.setAuthenticated(false);
      });
  }

  @Action({ rawError: true })
  async authenticateUser(payload: { username: string; password: string }) {
    this.setAuthenticating(true);
    await Cognito.authenticateUser(payload.username, payload.password)
      .then(() => {
        this.setAuthenticating(false);
        this.setAuthenticated(true);
      })
      .catch(() => {
        this.setAuthenticating(false);
        this.setAuthenticated(false);
      });
  }
}

export const CognitoState = getModule(CognitoStateModule);
