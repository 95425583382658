import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

const options = {
  icons: {
    iconfont: "md",
  },
};

export default new Vuetify({
  options: options,
});
